import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { SearchKeyService } from '@employer/app/services/search-key.service'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { from, of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { UserActionTypes } from 'shared-lib'
import {
  ErrorAction,
  GetJobPostCandidateSearchKey,
  GetJobPostCandidateSearchKeySuccess,
  GetJobPostSearchKeySuccess,
  GetProfileSearchKey,
  GetProfileSearchKeySuccess,
  GetUserSearchKey,
  GetUserSearchKeySuccess,
  Reset,
  SearchKeyActionTypes,
} from './search-key.actions'

@Injectable()
export class SearchKeyEffects {
  constructor(public router: Router, private actions$: Actions, private searchKeyService: SearchKeyService) {}

  onGetUserSearchKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchKeyActionTypes.getUserSearchKey),
      mergeMap((action: GetUserSearchKey) => {
        return from(this.searchKeyService.getCustomerUsersSearchKey(action.payload.key)).pipe(
          map((response: string) => {
            return new GetUserSearchKeySuccess(response)
          }),
          catchError(error => of(new ErrorAction(error)))
        )
      })
    )
  )

  onGetProfileSearchKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchKeyActionTypes.getProfileSearchKey),
      mergeMap(() => {
        return from(this.searchKeyService.getJobProfileSearchKey()).pipe(
          map((response: string) => {
            return new GetProfileSearchKeySuccess(response)
          }),
          catchError(error => of(new ErrorAction(error)))
        )
      })
    )
  )

  onGetPostSearchKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchKeyActionTypes.getPostSearchKey),
      mergeMap(() => {
        return from(this.searchKeyService.getJobPostSearchKey()).pipe(
          map((response: string) => {
            return new GetJobPostSearchKeySuccess(response)
          }),
          catchError(error => of(new ErrorAction(error)))
        )
      })
    )
  )

  onGetPostCandidateSearchKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SearchKeyActionTypes.getPostCandidateSearchKey),
      mergeMap((action: GetJobPostCandidateSearchKey) => {
        return from(this.searchKeyService.getJobPostCandidateKey(action.payload)).pipe(
          map((response: string) => {
            const candidateSearchKeyData = { jobPostId: action.payload, jobPostCandidateSearchKey: response }
            return new GetJobPostCandidateSearchKeySuccess(candidateSearchKeyData)
          }),
          catchError(error => of(new ErrorAction(error)))
        )
      })
    )
  )

  onUserLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActionTypes.logOutSuccess),
      map(success => new Reset())
    )
  )
}
