import { Injectable } from '@angular/core'
import { IJobPostContentItem, JobPostStatus } from '@employer/app/models/job-post.model'
import { JobPostRepository } from '@employer/app/repositories/job-post.repository'
import { environment } from '@employer/environments/environment'
import { E11ErrorHandlerService } from '@engineering11/web-api-error'
import { RestApiClient } from '@engineering11/web-api-rest'
import { TokenStorage } from 'shared-lib'

@Injectable({ providedIn: 'root' })
export class JobPostUpdateService {
  private restApiClient: RestApiClient
  constructor(tokenStorage: TokenStorage, private jobPostRepository: JobPostRepository, private errorHandler: E11ErrorHandlerService) {
    this.restApiClient = new RestApiClient({
      baseUrl: environment.services.jobs,
      token: () => tokenStorage.getAccessToken(),
    })
  }

  deactivateJobPost(jobPost: IJobPostContentItem, status: JobPostStatus) {
    if (jobPost.document.status === JobPostStatus.Published) {
      const body = { status }
      return this.restApiClient.put(`job-post/${jobPost.contentId}`, body)
    }
    return null
  }

  reactivateJobPost(jobPost: IJobPostContentItem) {
    if (jobPost.document.status === JobPostStatus.ClosedFilled || jobPost.document.status === JobPostStatus.ClosedNotFilled) {
      const body = { status: JobPostStatus.Published }
      return this.restApiClient.put(`job-post/${jobPost.contentId}`, body)
    }
    return null
  }
  pauseAllowJobPost(jobPost: IJobPostContentItem) {
    const body = { jobPaused: jobPost.document.jobPaused }
    return this.restApiClient.put(`job-post/${jobPost.contentId}`, body)
  }
}
